<template>
  <div>
    <form-printer
      ref="formPrinter"
      :page="pageConfig"
      :row="rowConfig"
      :table="tableConfig"
      :silent="false"
      :data-sources="dataLine"
      :info="dataInfo"
      @output-data="getPdfData"
      id="print-preview"
    ></form-printer>
  </div>
</template>
<script>
import FormPrinter from "@c_common/print/FormPrinter"
import { on } from '~assets/scripts/utils/dom'

const CONFIG = {
  GetConfigsUrl: "PrintTemplate/Get",
  StkOutSearchById: "StkOutAdd/Search"
}

export default {
  name: "PrintBroker",
  config: CONFIG,
  components: {
    FormPrinter
  },
  data() {
    return {
      rowConfig: [],
      pageConfig: {},
      tableConfig: {},
      dataInfo: {},
      dataLine: [],
      hasError: false
    };
  },
  props: {
  },
  watch: {
    $route (newVal, oldVal) {
      this.onInit()
    }
  },
  async mounted() {
    on(window, 'onPrintData', (e) => {
      console.log(e, 'on print data', e.data)
      let { TableName, MainGuid, TitleId } = e.data
      this.doPrint(TableName, MainGuid, TitleId)
    })

    await this.onInit()
  },
  created() {
  },
  methods: {
    async onInit () {
      const {TableName, MainGuid} = this.$route.query
      console.log(TableName, MainGuid, 23456)
      if (TableName === undefined || TableName === null || TableName === '') {
        console.log('table name is empty, print stop.')
        return
      }

      await this.doPrint(TableName, MainGuid)
    },

    async doPrint (tableName, mainGuid, titleId) {
      this.hasError = false

      //获取打印的数据源
      if (tableName.indexOf('STK_Out') === 0) {
        await this.getStkOutData(mainGuid)
      } else {
        console.log('table name is empty, print stop.')
        return
      }

      //获取打印配置
      if (!this.hasError) {
        await this.getConfigs(tableName)
      }

      //开始打印
      if (!this.hasError) {
        let datas = await this.$refs.formPrinter.printPDF()
        this.getPdfData(datas)
      }
    },

    async getConfigs (tableName) {
      await this.$post(CONFIG.GetConfigsUrl, { TableName: tableName }, (data, logic) => {
        if (data) {
          let cfg = JSON.parse(data.ConfigValue)
          if (cfg) {
            this.rowConfig = cfg.row
            this.pageConfig = cfg.page
            this.tableConfig = cfg.table
            // console.log(this.rowConfig, this.pageConfig, this.dataInfo, this.dataLine, 11234)
          }
        } else {
          this.$message({message: logic.msg || '找不到模板', type: 'error'})
          this.hasError = true
        }
      })
    },

    getPdfData (datas) {
      if (datas && datas.length > 0) {
        let param = {
          FileData: datas
        }
        console.log('get pdf data successfully. now start to send the datas to winform.')
        this.printBase64Pdf(param, () => {})
      } else {
        console.log('no pdf datas')
        this.$message({message: '生成打印文件失败', type: 'error'})
      }
    },

    async getStkOutData (StkOutGUID) {
      let stkOuts = await this.$post(CONFIG.StkOutSearchById, {
        StkOutGUID: StkOutGUID
      })
      this.dataInfo = stkOuts
      this.dataLine = stkOuts.LineList
    }
  }
};
</script>

<style lang="scss">
.m-operate-log {
  .el-pagination--small {
    height: auto !important;
  }
}
</style>

