<template>
<znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
  <print-broker></print-broker>
</znl-layout-spa>
</template>

<script>
import PrintBroker from '@c_common/print/PrintBroker'

export default {
  name: 'VPrintBroker',
  components: {
    PrintBroker
  },
  data () {
    return {
      pageData: [],
      pageLoading: true
    }
  },
  methods: {
  },
  async created () {
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>
